
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import config from "@/config";
import {queryProductSpecsListApi} from "@/apis/productSpecs";
import {ISpecs, ISpecsValue} from "@/apis/productSpecs/types";
import {Message} from "element-ui";
import {IProduct} from "@/apis/product/types";

@Component({})
export default class ProductSpecs extends Vue {
  config = config;
  // 新增修改表单
  @Prop(Object) modelForm!: IProduct;
  // 新增修改表单rules
  @Prop(Object) rules!: any;
  //商品规格下拉数据
  @Prop(Array) productSpecsValueList!: ISpecsValue[];
  // 新增修改表单
  modelSpecsForm: any = {}
  //商品规格下拉数据
  // productSpecsValueList: ISpecsValue[] = [];
  //商品规格属性下拉数据
  productSpecsList: ISpecs[] = [];

  //列出的属性 二维数组
  specsList: any[] = [];
  //选中的规格属性
  checkedSpecs: any[] = [];
  //列表 规格列数组
  tableColumnList:any[] = [];

  //列表数据
  tableData: any[] = []

  timer:any=null;

  @Watch('modelForm')
  async onChangeValue(newVal: any, oldVal: any) {
    console.log(newVal, '监听数据')
    try {
      //sku key value
      let SkuVoList: any = [];
      if (newVal.productSkuVoList) {
        for (let skuItem of newVal.productSkuVoList) {
          let obj = skuItem;
          let sku = JSON.parse(skuItem.spData);
          sku.forEach((e: any, i: number) => {
            obj[`specs${i}`] = e.value
          })
          SkuVoList = [...SkuVoList, ...sku]
          this.tableData.push(obj)
        }
        this.specsList = [];
        this.initSetSpecs(SkuVoList)
      }

    } catch (err) {
      console.log(err)

    }
  }
  /**
   * 商品规格回显
   */
   initSetSpecs(arrData:any[]){
    //规格列表
    this.productSpecsValueList.forEach(async (SpecsValue) => {
      for (let Item of arrData) {
        if (SpecsValue.specsName == Item.key) {
          console.log(SpecsValue.specsName == Item.key,'SpecsValue.specsName == Item.key',SpecsValue.specsName, Item.key)
          //规格对象
          let obj: any = SpecsValue;
          obj.specsName = Item.key;
          obj.specsValueId = SpecsValue.id;
          //查找 列表 规格是否存在
          let svIndex = this.specsList.findIndex(specs => specs.specsValueId == SpecsValue.id);
          if (svIndex == -1) {
            //获取规格属性列表
            await this.getProductSpecsList(SpecsValue.id || '');
            obj.list = this.productSpecsList;
            //查找 sku value 与规格属性 是否存在
            let sIndex = obj.list.findIndex((e: any) => e.name == Item.value);
            if (sIndex == -1) {
              let o = {
                specsName: obj.specsName,
                specsValueId: obj.specsValueId,
                name: Item.value,
                id:Math.floor((Math.random() + Math.floor(Math.random() * 9 + 1)) * 10 ** (13 - 1))
              }
              obj.list.push(o);
            }
            console.log(obj,'obj')

            this.specsList.push(obj);
          } else {
            //查找 sku value 与规格属性 是否存在
            let sIndex = this.specsList[svIndex].list.findIndex((e: any) => e.name == Item.value);
            if (sIndex == -1) {
              let o = {
                specsName: obj.specsName,
                specsValueId: obj.specsValueId,
                name: Item.value,
                id:Math.floor((Math.random() + Math.floor(Math.random() * 9 + 1)) * 10 ** (13 - 1))
              }
              this.specsList[svIndex].list.push(o);
            }
          }
          //查找 规格属性列表 是否与sku value 一致   设置多选框选中
          obj.list.forEach((e: any, i: number) => {
            if (e.name == Item.value) {
              this.checkedSpecs.push(e.id)
            }
          })
        }
      }
    })
    this.tableColumnList = this.specsList;
  }

  /**
   * 商品规格属性
   */
  async getProductSpecsList(id: string) {
    await queryProductSpecsListApi({specsValueId: id}).then(e => {
      this.productSpecsList = e;
    })
  }

  /**
   * 商品规格 选择
   *
   */
  handleSpecsValue(value: any) {
    this.modelSpecsForm.id = '';
    this.productSpecsList = [];
    this.getProductSpecsList(value);
  }

  /**
   * 商品规格列出
   *
   */
  handleSpecs() {
    //清除多选框
    this.checkedSpecs = [];

    let svIndex = this.productSpecsValueList.findIndex(e => e.id == this.modelSpecsForm.specsValueId);
    if (svIndex != -1) {
      let obj = {
        specsValueId: this.modelSpecsForm.specsValueId,
        specsName: this.productSpecsValueList[svIndex].specsName,
        list: [],
      }

      this.modelSpecsForm.id.forEach((id: any) => {
        let sIndex = this.productSpecsList.findIndex(e => e.id == id);
        if (sIndex != -1) {
          let o = this.productSpecsList[sIndex];
          o.specsName = obj.specsName
          obj.list.push(o as never)
        }
      })
      let index = this.specsList.findIndex((e: any) => e.specsValueId == this.modelSpecsForm.specsValueId);
      if (index != -1) {
        this.tableData = [];
        this.modelForm.productSkuDtoList = [];
        this.specsList.splice(index, 1, obj);
      } else
        this.specsList.push(obj);
    }
  }


  /**
   *选择规格属性
   * 清除 生成过的 sku列表数据
   */
  handleCheckedSpecs(value: any) {
    this.tableData = [];
    this.modelForm.productSkuDtoList = [];
  }

  /**
   * 添加规格属性
   */
  handleAddCheckboxName(specsValueId: string) {
    try {
      let index = this.specsList.findIndex((e: any) => e.specsValueId == specsValueId);
      let nIndex = this.specsList[index].list.findIndex((e: any) => e.name == this.specsList[index].checkboxName);
      if (nIndex != -1) {
        Message.error("不能输入重复的规格属性名！")
        this.specsList[index].checkboxName = '';
        return
      }
      this.specsList[index].list.push({
        specsName: this.specsList[index].specsName,
        specsValueId: this.specsList[index].specsValueId,
        name: this.specsList[index].checkboxName,
        id: Math.floor((Math.random() + Math.floor(Math.random() * 9 + 1)) * 10 ** (13 - 1))
      })
      this.specsList[index].checkboxName = '';
    } catch (err) {
      console.log(err)
    }

  }

  /**
   * 多维数组组合
   */
  // @ts-ignore
  doExchange(arr: any) {
    let len = arr.length;
    // 当数组大于等于2个的时候
    if (len >= 2) {
      // 第一个数组的长度
      let len1 = arr[0].length;
      // 第二个数组的长度
      let len2 = arr[1].length;
      // 2个数组产生的组合数
      let lenBoth = len1 * len2;
      //  申明一个新数组
      let items = new Array(lenBoth);
      // 申明新数组的索引
      let index = 0;
      for (let i = 0; i < len1; i++) {
        for (let j = 0; j < len2; j++) {
          if (arr[0][i] instanceof Array) {
            items[index] = arr[0][i].concat(arr[1][j]);
          } else {
            items[index] = [arr[0][i]].concat(arr[1][j]);
          }
          index++;
        }
      }
      let newArr = new Array(len - 1);
      for (let i = 2; i < arr.length; i++) {
        newArr[i - 1] = arr[i];
      }
      newArr[0] = items;
      return this.doExchange(newArr);
    } else {
      return arr[0];
    }
  }
  /**
   * 生成sku
   */
  handleSKU() {
    //列表
    this.tableData = [];
    //选择的sku 二维数组
    let SKUList = new Array();
    //过滤重复选择的数据
    let s = new Set( this.checkedSpecs);
    this.checkedSpecs= Array.from(s)

    this.specsList.forEach((item, index) => {
      item.isChecked=false;
      let specs: any = []
      item.list.forEach((sku: any) => {
        this.checkedSpecs.forEach((checkedId: any) => {
          let o: any = {id: '', specsValueId: item.specsValueId||'', skuName: item.specsName};
          if (sku.id == checkedId) {
            item.isChecked=true;
            o.id = sku.id;
            o[`specs${index}`] = sku.name;
            o.name = sku.name;
            specs.push(o);
          }
        })
      })
      if(specs.length>0)
      SKUList.push(specs);
    })

    if (SKUList) {
      this.tableColumnList=this.specsList.filter(e=>e.isChecked==true);
      //多维数组组合
      let newArr = this.doExchange(SKUList) as any;
      //组装sku key value List
      let spList = new Array();
      //组合完成 列表数据
      let productSkuList = [];
      //组合需要数据
      for (let newArrElement of newArr) {
        //列表对象
        let obj = {spData: null, skuPic: '', skuPrice: '', stock: '', productId: this.modelForm.id} as any;

        if (!newArrElement.length) {
          for (let skuListElement of this.specsList) {
            for (let skuVoListElement of skuListElement.list) {
              if (skuVoListElement.specsValueId == newArrElement.specsValueId && skuVoListElement.name == newArrElement.name) {
                //规格列 key,value
                obj.specs0 = newArrElement.name;
                //spData  key value
                spList.push({key: skuListElement.specsName, value: newArrElement.name});
                continue;
              }
            }
          }
        } else {
          for (let i = 0; i < newArrElement.length; i++) {
            for (let skuListElement of this.specsList) {
              for (let skuVoListElement of skuListElement.list) {
                if (skuVoListElement.specsValueId == newArrElement[i].specsValueId && skuVoListElement.name == newArrElement[i].name) {
                  //规格列 key,value
                  obj[`specs${i}`] = newArrElement[i].name
                  //spData  key value
                  spList.push({key: skuListElement.specsName, value: newArrElement[i].name});
                  continue;
                }
              }
            }
          }
        }
        obj.spData = JSON.stringify(spList);
        productSkuList.push(obj)
        spList = [];
      }
      this.tableData = productSkuList;
      this.modelForm.productSkuDtoList = this.tableData;
      console.log(this.tableData, 'productSkuDtoList')
    }

  }

  /**
   * 上传前检验
   * @param file
   */
  beforeUpload(file: File) {
    if (file.type != "image/png" && file.type != "image/jpg" && file.type != "image/jpeg") {
      Message.error("请选择正确格式")
      return false
    }
  }

  /**
   * 下一步，上一步
   * @param number
   *
   */
  handleNext(tab: string) {
    this.$emit('Next', this.modelForm,tab)
  }

  created() {
  }
  beforeDestroy() {
    clearTimeout(this.timer);
  }
}
